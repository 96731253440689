export const ORDER_CARD_TABS = [
  {
    label: "Select Card",
    path: "select",
  },
  {
    label: "Payment Method",
    path: "payment-method",
  },
  {
    label: "Order Confirmation",
    path: "confirmation",
  },
  {
    label: "Order Instruction",
    path: "instruction",
  },
  {
    label: "Transaction ID",
    path: "transaction-id",
  },
]
