import { FormProvider, UseFormReturn } from "react-hook-form"

interface Props<T extends object> {
  model: UseFormReturn<T>
  onSubmit: (data: T) => void
  children: React.ReactNode
}
export const FormWrapper = <T extends object>({ model, onSubmit, children }: Props<T>) => {
  return (
    <FormProvider {...model}>
      <form autoComplete="off" onSubmit={model.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  )
}
