import { IOption } from "@/types"
import { RefObject, useMemo } from "react"
import countries from "react-intl-tel-input-18/dist/components/AllCountries"
import { Selector } from "../Selector"

export const EXCLUDED_COUNTRIES: string[] = [
  "af",
  "al",
  "by",
  "cf",
  "cu",
  "cd",
  "er",
  "et",
  "ir",
  "iq",
  "lb",
  "ly",
  "ml",
  "mm",
  "ni",
  "kp",
  "ru",
  "so",
  "ss",
  "sd",
  "sy",
  "tn",
  "tr",
  "ua",
  "ve",
  "ye",
  "zw",
  "cn",
  "kw",
  "me",
  "ng",
  "vn",
]

export const EXCLUDED_COUNTRIES_LIST = countries.getCountries().filter((x) => EXCLUDED_COUNTRIES.includes(x.iso2 ?? ""))
export const AVAILABLE_COUNTRIES_LIST = countries.getCountries().filter((x) => x.iso2 && !EXCLUDED_COUNTRIES.includes(x.iso2 ?? ""))

type CountryPhone = IOption
interface ICountrySelectProps {
  label?: string
  dark?: boolean
  disabled?: boolean
  defaultCountry?: string
  firstCountries?: string[]
  error?: string
  value?: string
  onChange?: (country: CountryPhone | null) => void
  withDialCode?: boolean
  withFlag?: boolean
  onlyFlag?: boolean
  phoneNumber?: string
  inputRef?: RefObject<any>
  placeholder?: string
  searchable?: boolean
}
export const CountrySelectComponent = ({
  withDialCode = false,
  error,
  firstCountries = ["gb"],
  defaultCountry = "gb",
  label,
  placeholder,
  withFlag = false,
  value,
  disabled,
  onChange,
  phoneNumber,
  onlyFlag = false,
  searchable,
}: ICountrySelectProps) => {
  const allCountries = useMemo(() => {
    const result = AVAILABLE_COUNTRIES_LIST.reduce<Record<string, CountryPhone>>(
      (acc, country) => ({
        ...acc,
        [country.iso2 ?? ""]: {
          label: withDialCode ? `${country.name} +${country.dialCode}` : country.name ?? "",
          value: `${country.iso2}`,
          meta: {
            iso2: country.iso2,
            dialCode: country.dialCode,
            priority: country.priority ?? 0,
          },
        },
      }),
      {}
    )
    const firsts = firstCountries?.map((first) => result[first.toLowerCase()])
    firstCountries?.forEach((first) => delete result[first])
    return [...(firsts ?? []), ...Object.values(result)]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCountries])
  const selectedCountry = useMemo(() => {
    const cntr =
      allCountries.find((country) => {
        if (phoneNumber != null) {
          // @ts-expect-error
          return phoneNumber?.replace(/\D/g, "")?.startsWith(country.meta?.dialCode)
        }

        return country.value === (value || defaultCountry)
      }) ?? null

    return cntr
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, defaultCountry, phoneNumber])

  return (
    <>
      <Selector
        value={selectedCountry}
        options={allCountries}
        showValue={!onlyFlag}
        disabled={disabled}
        label={label}
        onChange={(value) => {
          onChange?.(value as IOption)
        }}
        placeholder={placeholder}
        leftSection={
          withFlag
            ? () =>
                selectedCountry?.value ? (
                  <div className={`iti-flag ${selectedCountry?.value} min-w-[20px]`} />
                ) : onlyFlag ? (
                  <div className="min-w-[20px]" />
                ) : null
            : undefined
        }
        renderOptions={(option, optionsProps) => (
          <div className="flex items-center gap-2" {...optionsProps}>
            <div className={`iti-flag ${option?.value} min-w-[fit-content]`} />
            <span className="text-xs">{option?.label}</span>
          </div>
        )}
        searchable={searchable}
      />
      {error != null && <span className="selector-custom__error-message">{error}</span>}
    </>
  )
}

export const CountrySelect = ({
  onlyValue,
  value,
  onChange,
  ...props
}: ICountrySelectProps & {
  onlyValue?: boolean
  onChange: (value: CountryPhone) => void
  value: IOption
}) => {
  return (
    <CountrySelectComponent
      defaultCountry=""
      {...props}
      value={onlyValue ? value : (value as IOption)?.value}
      onChange={(x) => {
        if (onlyValue) {
          onChange(x)
        } else {
          onChange(x)
        }
      }}
    />
  )
}
