import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import { orderCardRoutes } from "./OrderCard"

export const widgetsRoutes: RouteObject[] = [
  {
    path: "/widgets",
    element: <Navigate to="order-card" replace />,
  },
  {
    path: "order-card",
    Component: lazy(async () => await import("./OrderCard").then((module) => ({ default: module.OrderCardWidget }))),
    children: orderCardRoutes,
  },
]
