export const BASE_URL = process.env.REACT_APP_BASE_URL as string

export const MERCHANT_API_KEY = process.env.REACT_APP_MERCHANT_API_KEY as string
export const MERCHANT_SERVICE_ID = process.env.REACT_APP_MERCHANT_SERVICE_ID as string
export const MERCHANT_SECRET_KEY = process.env.REACT_APP_MERCHANT_SECRET_KEY as string

export const ENV = process.env.REACT_APP_ENV as string

export const ORDER_CARD_CRYPTOS = [
  {
    label: "Bitcoin",
    icon: "BTC",
  },
  {
    label: "Ethereum",
    icon: "ETH",
  },
  {
    label: "Litecoin",
    icon: "LTC",
  },
  {
    label: "USDT-TRC20",
    icon: "USDT",
  },
  {
    label: "USDT-ERC20",
    icon: "USDT",
  },
  {
    label: "USDT-BEP20",
    icon: "USDT",
  },
  {
    label: "USDC-ERC20",
    icon: "USDC",
  },
  {
    label: "USDC-TRC20",
    icon: "USDC",
  },
  {
    label: "USDC-BEP20",
    icon: "USDC",
  },
  {
    label: "DASH",
    icon: "DASH",
  },
  {
    label: "ICP",
    icon: "ICP",
  },
  {
    label: "BNB",
    icon: "BNB",
  },
  {
    label: "NUM",
    icon: "NUM",
  },
]
export const ORDER_CARD_CRYPTOS_SIGNS: Record<string, string> = {
  USD: "$",
  EUR: "€",
}

export const ORDER_CARD_CRYPTOS_NAMES: Record<string, { label: string; icon: string }> = {
  BTC: {
    label: "Bitcoin",
    icon: "BTC",
  },
  BCH: {
    label: "Bitcoin Cash",
    icon: "BCH",
  },
  ETH: {
    label: "Ethereum",
    icon: "ETH",
  },
  LTC: {
    label: "Litecoin",
    icon: "LTC",
  },
  "USDT-TRC20": {
    label: "USDT-TRC20",
    icon: "USDT",
  },
  USDT: {
    label: "USDT",
    icon: "USDT",
  },
  "USDT-ERC20": {
    label: "USDT-ERC20",
    icon: "USDT",
  },
  "USDT-BEP20": {
    label: "USDT-BEP20",
    icon: "USDT",
  },
  "USDC-ERC20": {
    label: "USDC-ERC20",
    icon: "USDC",
  },
  "USDC-TRC20": {
    label: "USDC-TRC20",
    icon: "USDC",
  },
  "USDC-BEP20": {
    label: "USDC-BEP20",
    icon: "USDC",
  },
  DASH: {
    label: "DASH",
    icon: "DASH",
  },
  ICP: {
    label: "ICP",
    icon: "ICP",
  },
  BNB: {
    label: "BNB",
    icon: "BNB",
  },
  NUM: {
    label: "NUM",
    icon: "NUM",
  },
}

export const DENOMINATIONS = [
  {
    label: "5 USD",
    value: "5",
  },
  {
    label: "10 USD",
    value: "10",
  },
  {
    label: "20 USD",
    value: "20",
  },
  {
    label: "50 USD",
    value: "50",
  },
  {
    label: "100 USD",
    value: "100",
  },
  {
    label: "200 USD",
    value: "200",
  },
  {
    label: "500 USD",
    value: "500",
  },
  {
    label: "1000 USD",
    value: "1000",
  },
  {
    label: "1500 USD",
    value: "1500",
  },
]

export const VALID_NAME = {
  minLength: {
    value: 2,
    message: "The minimum field length 2 symbols.",
  },
  pattern: {
    value: /^[a-zA-Z\s]*$/,
    message: "Only Latin letters are allowed.",
  },
}

export const VALID_EMAIL = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Invalid email address.",
  },
}

export const VALID_PHONE = {
  minLength: {
    value: 8,
    message: "The minimum field length 8 digits.",
  },
  maxLength: {
    value: 14,
    message: "The maximum field length 14 digits.",
  },
  pattern: {
    value: /^[+]?[0-9]{3}[0-9]{3}[0-9]{4,8}$/,
    message: "Invalid phone number. For example +18123452679",
  },
}

export const VALID_PASSWORD = {
  minLength: {
    value: 6,
    message: "The minimum field length 6 symbols.",
  },
}

export const CARD_TYPES = [
  { label: "Visa", value: "1" },
  { label: "Mastercard", value: "2" },
]
