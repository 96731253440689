import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from "react"
import "./style.sass"
import { IFormControl, IOption } from "@/types"
import { autoUpdate, flip, offset, size, useDismiss, useFloating } from "@floating-ui/react"
import { DropdownIcon } from "./DropdownIcon"
import { FieldValues, Controller } from "react-hook-form"
import clsx from "clsx"
import { cn } from "@/utils"

interface ISelectorProps {
  options: IOption[]
  value?: IOption | string | null
  onChange?: (value: IOption) => void
  classNames?: Partial<{
    control: string
    list: string
  }>
  renderOptions?: (option: IOption, props: HTMLAttributes<HTMLDivElement> & { key: any }) => ReactNode
  leftSection?: ((value: string) => ReactNode) | ReactNode
  rightSection?: ReactNode
  fullWidth?: boolean
  variant?: "outlined" | "text" | "contained"
  searchable?: boolean
  disabled?: boolean
  label?: string
  showValue?: boolean
  error?: string
  placeholder?: string
  className?: string
}

export const Selector = ({
  renderOptions,
  leftSection,
  rightSection,
  value,
  options,
  fullWidth,
  onChange,
  classNames,
  showValue = true,
  disabled = false,
  variant = "contained",
  searchable,
  placeholder,
  label,
  error,
  className,
}: ISelectorProps) => {
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>(options)

  const { refs, floatingStyles, placement, context } = useFloating({
    placement: "bottom-start",
    open: open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
        padding: 10,
      }),
    ],
  })
  useDismiss(context)
  const close = () => setOpen(false)
  const selectValue = useMemo(() => {
    if (typeof value === "object") return options?.find((option) => option.value === value?.value)?.label ?? ""
    return options?.find((option) => option.value === value)?.label ?? ""
  }, [options, value])
  useEffect(() => {
    setFilteredOptions(options)
  }, [options])
  useEffect(() => {
    if (searchable && variant !== "text") {
      if (value == null) {
        setInputValue("")
      } else if (typeof value === "object") {
        setInputValue(value?.label)
      } else {
        setInputValue(value ?? "")
      }
    }
  }, [value, searchable, variant])
  return (
    <div className={clsx("relative", className)}>
      {label != null && <span className="text-label text-sm whitespace-nowrap">{label}</span>}
      <div
        ref={refs.setReference}
        className={cn(
          `selector-custom selector-custom__${variant}`,
          label != null && "mt-2.5",
          error != null && "selector-custom__error",
          classNames?.control,
          fullWidth && "selector-custom__full-width",
          disabled && "selector-custom__disabled"
        )}
        onClick={() => {
          if (!disabled) {
            setOpen(!open)
          }
        }}
      >
        <div style={leftSection != null ? { marginLeft: -10 } : {}}>
          {typeof leftSection === "function" ? leftSection(selectValue) : leftSection}
          {searchable && variant !== "text" ? (
            <input
              disabled={disabled}
              className="flex-1"
              value={inputValue}
              placeholder={placeholder}
              onChange={(e) => setInputValue(e.target.value)}
              onInput={(e) => {
                if (!open) setOpen(true)
                return setFilteredOptions(options.filter((opt) => opt.label.toLowerCase().startsWith(e.currentTarget.value.toLowerCase())))
              }}
            />
          ) : showValue ? (
            <span className="text-black" style={{ color: !selectValue || selectValue === "" ? "#9BA3AF" : undefined }}>
              {!selectValue || selectValue === "" ? placeholder ?? "" : selectValue}
            </span>
          ) : null}
        </div>
        {rightSection}
        <div className="dropdown-icon">
          <DropdownIcon up={open} />
        </div>
      </div>
      {error != null && error !== "empty_error" ? <span className="selector-custom__error-message">{error}</span> : null}
      {open && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={clsx(
            `select-custom__list border border-red-500 ${classNames?.list ?? ""} h-fit overflow-hidden flex flex-col`,
            placement.startsWith("top") && "flex-col-reverse"
          )}
        >
          {searchable && variant === "text" && (
            <div>
              <input
                disabled={disabled}
                className="text-sm flex-1 text-black w-full px-[20px] py-2.5 border-b"
                placeholder="Search..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onInput={(e) => {
                  if (!open) setOpen(true)
                  return setFilteredOptions(options.filter((opt) => opt.label.toLowerCase().startsWith(e.currentTarget.value.toLowerCase())))
                }}
              />
            </div>
          )}
          <div className="select-custom__list-wrapper overflow-y-auto">
            {filteredOptions.map((option, index) =>
              renderOptions != null ? (
                renderOptions(option, {
                  className: cn(
                    "flex gap-[12px] select-custom__list-item whitespace-nowrap",
                    variant === "text" ? "min-w-[200px]" : "min-w-[120px]",
                    option.label === selectValue && "selector-custom__selected"
                  ),
                  key: index,
                  onClick: () => {
                    onChange?.(option)
                    close()
                  },
                })
              ) : (
                <div
                  className={cn(
                    "select-custom__list-item whitespace-nowrap",
                    variant === "text" ? "min-w-[200px]" : "min-w-[120px]",
                    option.label === selectValue && "selector-custom__selected"
                  )}
                  key={index}
                  onClick={() => {
                    onChange?.(option)
                    close()
                  }}
                >
                  {option.label}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

interface IFormSelectProps extends ISelectorProps {
  className?: string
  onlyValue?: boolean
}

const FormSelect = <T extends FieldValues>({
  control,
  name,
  rules,
  required,
  className,
  onlyValue,
  ...props
}: IFormControl<T> & IFormSelectProps) => {
  return (
    <Controller
      {...{ control, name, rules: { required: { value: required ?? false, message: "This field is required" }, ...(rules ?? {}) } }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={className}>
            <Selector error={error?.message} {...props} value={value} onChange={onlyValue ? (value) => onChange(value.value) : onChange} />
          </div>
        )
      }}
    />
  )
}

Selector.Form = FormSelect
