import { useLocation, useNavigate } from "react-router-dom"
import "./style.sass"
import clsx from "clsx"
import SimpleBar from "simplebar-react"
import { useEffect, useRef } from "react"
interface ITabItem {
  label: string
  path: string
}
interface Props {
  items: ITabItem[]
  activeTab?: ITabItem
  className?: string
  isCanNavigateByClick?: boolean
  onSelect?: (item: ITabItem) => void
}

export const Tab = ({ className, items, onSelect, isCanNavigateByClick = true }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const checkActive = (path: string) => {
    const regex = new RegExp(`/?${path}/?`)
    return regex.test(pathname)
  }

  useEffect(() => {
    if (ref.current != null)
      setTimeout(() => {
        const el = document.getElementById("active-tab-item")
        if (el != null) el?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
      }, 100)
  }, [ref, pathname])

  return (
    <SimpleBar classNames={{ track: "hidden" }} className="flex-[1_1_0] max-w-full w-full">
      <div ref={ref} className={clsx("custom-tab", className)}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`custom-tab__item text-lg ${checkActive(item.path) ? "custom-tab__item--active" : ""}`}
            id={checkActive(item.path) ? "active-tab-item" : undefined}
            onClick={() => {
              if (isCanNavigateByClick) {
                onSelect?.(item)
                if (item.path != null) {
                  navigate(item.path)
                }
              }
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    </SimpleBar>
  )
}
