import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom"
import dashboardRoutes from "./pages/routes"
import { lazy, Suspense } from "react"
import authRoutes from "./pages/Auth/routes"
import { AuthView } from "./views/auth"
import { ToastContainer } from "react-toastify"
import { widgetsRoutes } from "./pages/Widgets"

const mainRouts: RouteObject[] = [
  {
    path: "/",
    Component: lazy(async () => import("./pages/Home")),
  },
  {
    path: "/policy",
    Component: lazy(async () => import("./pages/Policy")),
    children: [
      {
        path: "/policy",
        element: <Navigate to="terms-of-use" />,
      },
      {
        path: "terms-of-use",
        Component: lazy(async () => import("./pages/Policy/TermsOfUse")),
      },
      {
        path: "privacy-policy",
        Component: lazy(async () => import("./pages/Policy/PrivacyPolicy")),
      },
      {
        path: "aml-kyc",
        Component: lazy(async () => import("./pages/Policy/AmlKyc")),
      },
    ],
  },
  {
    path: "/blogs",
    Component: lazy(async () => import("./pages/Blogs")),
  },
  {
    path: "/blogs/:id",

    Component: lazy(async () => import("./pages/Blogs/BlogView")),
  },
  {
    path: "/contact-us",
    Component: lazy(async () => import("./pages/ContactUs")),
  },
  {
    path: "/affiliate",
    Component: lazy(async () => import("./pages/Affiliate")),
  },
  {
    path: "/faq",
    Component: lazy(async () => import("./pages/FAQ")),
  },
  {
    path: "/faq-view/:parent?/:id?",
    Component: lazy(async () => import("./pages/FAQ/FaqView")),
  },
  {
    path: "/check-balance",
    Component: lazy(async () => import("./pages/CheckBalance")),
  },
  {
    path: "/auth",
    element: <AuthView />,
    children: authRoutes,
  },
  {
    path: "/dashboard",
    children: dashboardRoutes,
  },
  {
    path: "/widgets",
    children: widgetsRoutes,
  },
]

const Routes = () => {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <RouterProvider router={createBrowserRouter(mainRouts)} />
    </Suspense>
  )
}
function App() {
  return (
    <div className="App">
      <ToastContainer hideProgressBar limit={5} position="top-right" />
      <Routes />
    </div>
  )
}

export default App
