import { lazy } from "react"
import { Navigate } from "react-router-dom"

export const orderCardRoutes = [
  {
    path: "/widgets/order-card",
    element: <Navigate to="select" replace />,
  },
  {
    path: "select",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/SelectCards")),
  },
  {
    path: "payment-method",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/PaymentMethod")),
  },
  {
    path: "confirmation",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/OrderConfirmation")),
  },
  {
    path: "instruction",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/OrderInstruction")),
  },
  {
    path: "transaction-id",
    Component: lazy(async () => await import("../../Dashboard/OrderCard/Sections/TransactionID")),
  },
]
