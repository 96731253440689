import OrderCard from "@/pages/Dashboard/OrderCard"
import React, { memo } from "react"
import type { FC } from "react"

type Props = {
  //
}

const OrderCardWidgetComponent: FC<Props> = () => {
  return <OrderCard isWidget />
}

export const OrderCardWidget = memo(OrderCardWidgetComponent)
