import { Notice } from "@/components/Notice"
import { BASE_URL, ENV, MERCHANT_API_KEY, MERCHANT_SECRET_KEY, MERCHANT_SERVICE_ID, ORDER_CARD_CRYPTOS_NAMES } from "@/constants"
import { IOption } from "@/types"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import CryptoJS from "crypto-js"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AVAILABLE_COUNTRIES_LIST } from "@/components/NewSelector/CoutrySelect"

const EXCHANGE_URL = `${BASE_URL}/api/exchange.htm`
const CARDS_API_URL = `${BASE_URL}/api/cards/api.htm`

export type PaymentMethod = { label: string; icon: string; rate: number; amount: number; crypto: string }

interface IForm {
  email: string
  firstName: string
  lastName: string
  state: string
  city: string
  address: string
  apartment: string
  zip: string
  phoneNumber: string
  cardType: IOption<string> | null
  denomination: string
  code: string
  code2: string
  phoneCode: string
  wallet: string
  currency: IOption<string> | null
  userCountry: IOption<string> | null
  country: IOption<string> | null
  paymentMethod: PaymentMethod | null
  transactionHash: string
}

export type VerifyResponse = {
  method: string
  refid: string
  id: string
  info: string | null
  status: string
  fee: string
  fiat_amount: string
  currency_fiat: string
  crypto_amount: string
  currency_crypto: string
  amount_withdrawal: string
  commission: string
  txid: string
  wallet: string
  timestamp: string
  card_pan: string
  card_type: string
  confirmation: number
}

type BandInfo = {
  id: string
  name: string
  country: string
  currency_id: string
  product_id: string
  card_type: string
  min: number | undefined
  max: number | undefined
}

type ConfirmResult = {
  token: string
  timestamp: string
  transaction_id: number
  ex_transaction_id: number
  fiat_amount: number
  fiat_currency: string
  crypto_amount: number
  crypto_currency: string
  amount_withdrawal: string
  wallet: string
}

type Fee = {
  commission: number
  commission_fix: number
  currency: string
  symbol: string
}

export const DEFAULT_VALUES = {
  email: "",
  firstName: "",
  lastName: "",
  state: "",
  phoneCode: "",
  city: "",
  phoneNumber: "",
  address: "",
  apartment: "",
  zip: "",
  code: "",
  code2: "",
  denomination: "",
  cardType: null,
  country: null,
  userCountry: null,

  transactionHash: "",
  wallet: "",
  currency: null,
}

export const useSelectCardForm = (defaultValues = DEFAULT_VALUES) => {
  return useForm<IForm>({
    defaultValues,
  })
}

export const useSelectCards = (model: UseFormReturn<IForm, any, undefined>, isLanding = false, isWidget = false) => {
  const navigate = useNavigate()

  const currency = model.watch("currency")
  const country = model.watch("country")
  const code = model.watch("code")
  const code2 = model.watch("code2")
  const cardType = model.watch("cardType")
  const denomination = model.watch("denomination")

  console.log(">>>>>>>>>", currency)

  const [confirmResult, setConfirmResult] = useState<ConfirmResult>({
    token: "",
    timestamp: "",
    transaction_id: 0,
    ex_transaction_id: 0,
    fiat_amount: 0,
    fiat_currency: "",
    crypto_amount: 0,
    crypto_currency: "",
    amount_withdrawal: "",
    wallet: "",
  })
  const [isLoadingRates, setIsLoadingRates] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false)
  const [verifyResponse, setVerifyResponse] = useState<VerifyResponse | null>(null)
  const [activeStep, setActiveStep] = useState(1)
  const [isSelecting, setIsSelecting] = useState(false)
  const [brandInfo, setBrandInfo] = useState<BandInfo | null>(null)
  const [rates, setRates] = useState<PaymentMethod[]>([])
  const [cardTypes, setCardTypes] = useState<IOption<string>[]>([])
  const [fees, setFees] = useState<Record<string, Fee>>({})
  const [flatPrices, setFlatPrices] = useState<Record<string, number>>({})
  const [flatPricesOptions, setFlatPricesOptions] = useState<IOption<string>[]>([])
  const [sessionId, setSessionId] = useState<string | null>(null)

  const [searchParams] = useSearchParams()

  const isVisaCardType = useMemo(() => cardType?.label?.toLowerCase?.().includes("visa"), [cardType?.label])

  const handleNavigate = useCallback(
    (path: string) => {
      const basePath = isWidget ? "/widgets" : "/dashboard"

      return navigate(`${basePath}${path}`)
    },
    [isWidget, navigate]
  )

  const handleRequest = useCallback(
    (action: string, data?: Record<string, string>) => {
      const nextData = {
        ...data,
      }

      if (sessionId) {
        nextData.session_id = sessionId
        delete nextData.s_id
      }

      return fetch(EXCHANGE_URL, {
        method: "POST",
        body: new URLSearchParams({
          action,
          ...nextData,
        }),
      }).then((response) => response.json())
    },
    [sessionId]
  )

  useEffect(() => {
    if (isWidget) {
      if (ENV === "staging" || ENV === "development") {
        const serviceId = MERCHANT_SERVICE_ID
        const secretKey = MERCHANT_SECRET_KEY
        const apiKey = MERCHANT_API_KEY

        const timestamp = `${Date.now()}`
        const payload = `service_id=${serviceId}`

        const signature = CryptoJS.HmacSHA256(`${apiKey}${timestamp}${payload}`, secretKey).toString(CryptoJS.enc.Hex)

        const form = new FormData()

        form.append("action", "get_session")
        form.append("service_id", serviceId)

        fetch(CARDS_API_URL, {
          method: "POST",
          headers: {
            "X-SIGNATURE": signature,
            "X-TIMESTAMP": timestamp,
            "X-API-KEY": apiKey,
          },
          body: form,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success && typeof data.data === "string") {
              setSessionId(data.data)
            } else {
              Notice.error(data.message ?? "Something wrong")
            }
          })
          .catch((e) => Notice.error(e.message ?? "Something wrong"))
      }
    }
  }, [isWidget])

  useEffect(() => {
    if (ENV === "production") {
      setSessionId(searchParams.get("session_id"))
    }
  }, [searchParams])

  const handleCalculateAmount = useCallback(
    (
      nextFees: Record<string, Fee>,
      nextFlatPrices: Record<string, number>,
      rate: number,
      nextCrypto: string | undefined,
      nextCurrency: string | undefined,
      nextDenomination: string
    ) => {
      const amount = nextDenomination ? Number(nextDenomination) : 0
      const fee = nextCrypto ? nextFees[nextCrypto] ?? {} : ({} as Fee)
      const price = nextCurrency ? nextFlatPrices[nextCurrency] ?? 0 : 0

      return ((amount + ((amount * (fee.commission ?? 0)) / 100 + (fee.commission_fix ?? 0))) / price) * rate
    },
    []
  )

  const onRefresh = useCallback(
    (isInit?: boolean) => {
      setIsLoadingRates(true)

      const values = model.getValues()

      const data = {
        card_type: values?.cardType?.value ?? "",
        s_id: values.code2 && values.code2 !== "" ? values.code2 : values.code && values.code !== "" ? values.code : "",
      }

      handleRequest("get_rates_card", data)
        .then((data) => {
          if (data.success) {
            const prices = data?.data?.fiat_prices ?? {}
            const fees = Array.isArray(data?.data?.fees)
              ? // @ts-expect-error
                (data.data.fees.reduce<any>(
                  (acc: any, item: any) => ({
                    ...acc,
                    [item.symbol]: { ...item, commission_fix: item.commission_fix ? Number(item.commission_fix) : 0 },
                  }),
                  {}
                ) as any)
              : {}

            const prevPaymentMethod = model.getValues().paymentMethod
            const prevCurrency = model.getValues().currency
            const prevDenomination = model.getValues().denomination

            if (!isInit) {
              if (prevPaymentMethod) {
                model.setValue("paymentMethod", {
                  ...prevPaymentMethod,
                  amount: handleCalculateAmount(
                    fees,
                    prices,
                    prevPaymentMethod?.rate,
                    prevPaymentMethod.crypto,
                    prevCurrency?.label,
                    prevDenomination
                  ),
                })
              }
            }

            setFees(fees)
            setFlatPrices(prices)
            setRates(
              Object.entries<number>(data?.data?.crypto_prices ?? {}).map(([key, value]) => ({
                label: ORDER_CARD_CRYPTOS_NAMES[key]?.label ?? "",
                icon: ORDER_CARD_CRYPTOS_NAMES[key]?.icon ?? "",
                crypto: key,
                rate: value ?? 0,
                amount: isInit ? 0 : handleCalculateAmount(fees, prices, value, key, prevCurrency?.label, prevDenomination),
                fee: fees[key],
              }))
            )

            if (isInit) {
              setActiveStep(2)
              handleNavigate("/order-card/payment-method")
            }
          } else {
            Notice.error(data.message ?? "Something wrong!")
          }
        })
        .catch((e) => {
          Notice.error(e.message ?? "Something wrong!")
        })
        .finally(() => setIsLoadingRates(false))
    },
    // Don't add 'model' dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCalculateAmount, isLanding, handleRequest]
  )

  useEffect(() => {
    if (isLanding) {
      onRefresh(true)
    }
  }, [isLanding, onRefresh])

  useEffect(() => {
    if (!isWidget || sessionId) {
      handleRequest("get_card_types")
        .then((data) => {
          if (data.success) {
            const cardTypes = Object.entries<string>(data.data ?? {}).map(([value, label]) => ({ label, value }))

            const mcCardType = cardTypes.find((x) => x.label.toLowerCase().includes("master"))

            if (mcCardType) {
              model.setValue("cardType", mcCardType)
            }

            setCardTypes(cardTypes)
          } else {
            Notice.error(data.message ?? "Something wrong!")
          }
        })
        .catch((e) => {
          Notice.error(e.message ?? "Something wrong!")
        })
        .finally(() => setIsLoadingRates(false))
    }
  }, [handleRequest, sessionId, isWidget])

  useEffect(() => {
    if (isVisaCardType) {
      const country = AVAILABLE_COUNTRIES_LIST.find((x) => x.iso2 === "us")

      model.setValue(
        "country",
        country != null
          ? {
              label: country.name ?? "",
              value: `${country.iso2}`,
              meta: {
                iso2: country.iso2,
                dialCode: country.dialCode,
                priority: country.priority ?? 0,
              },
            }
          : null
      )
    }
  }, [isVisaCardType])

  useEffect(() => {
    if (cardType?.value && country?.value) {
      const data = {
        country: country.value,
        card_type: cardType.value,
      }

      handleRequest("get_gift_currency", data)
        .then((data) => {
          if (data.success) {
            const options = ((data.data ?? []) as Array<{ id: string; name: string }>).map((x) => ({ label: x.name, value: x.id }))

            if (options.length === 1) {
              model.setValue("currency", options?.[0] ?? null)
            } else {
              model.setValue("currency", null)
            }

            setFlatPricesOptions(options)
          } else {
            Notice.error(data.message ?? "Something wrong!")
          }
        })
        .catch((e) => {
          Notice.error(e.message ?? "Something wrong!")
        })
        .finally(() => setIsLoadingRates(false))
    }
  }, [cardType?.value, country?.value, handleRequest])

  useEffect(() => {
    if (currency?.value && country?.value) {
      const data = {
        fiat_currency: currency.label,
        country: country.value,
        card_type: cardType?.value ?? "",
        s_id: code2 && code2 !== "" ? code2 : code && code !== "" ? code : "",
      }

      handleRequest("get_card_brand_info", data)
        .then((data) => {
          if (data.success) {
            setBrandInfo(
              data.data
                ? {
                    ...data.data,
                    min: data.data.min ? Number(data.data.min) : undefined,
                    max: data.data.max ? Number(data.data.max) : undefined,
                  }
                : null
            )
          } else {
            Notice.error(data.message ?? "Something wrong!")
          }
        })
        .catch((e) => {
          Notice.error(e.message ?? "Something wrong!")
        })
        .finally(() => setIsLoadingRates(false))
    }
  }, [currency?.label, cardType?.value, country?.value, code, currency?.value, handleRequest, code2])

  const [searchValue, setSearchValue] = useState("")
  const [page, setPage] = useState(0)

  const filteredCryptos = useMemo(() => {
    if (searchValue === "") {
      return rates.slice(page * 5, page * 5 + 5).map((x) => {
        const rate = x.rate ?? 0

        return { ...x, amount: handleCalculateAmount(fees, flatPrices, rate, x.crypto, currency?.label, denomination) }
      })
    }

    return rates
      .filter((crypto) => crypto.label.toLowerCase().startsWith(searchValue.toLowerCase()))
      .map((x) => {
        const rate = x.rate ?? 0

        return { ...x, amount: handleCalculateAmount(fees, flatPrices, rate, x.crypto, currency?.label, denomination) }
      })
  }, [searchValue, rates, page, handleCalculateAmount, fees, flatPrices, denomination, currency?.label])

  const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => model.setValue("paymentMethod", paymentMethod), [model])

  const onSelect = useCallback(() => {
    setIsSelecting(true)

    const values = model.getValues()

    const data = {
      fiat_amount: values?.denomination,
      fiat_currency: `${values?.currency?.label}`,
      crypto_amount: `${values?.paymentMethod?.amount}`,
      crypto_currency: `${values?.paymentMethod?.crypto}`,
      email: values?.email,
      client_country: values?.userCountry?.value ?? "",
      country: values?.country?.value ?? "",
      first_name: values?.firstName,
      last_name: values?.lastName,
      state: values?.state,
      city: values?.city,
      card_type: values.cardType?.value ?? "",
      address: values?.address,
      address2: values?.apartment,
      zip: values?.zip,
      phone: values?.phoneNumber,
      s_id: values.code2 && values.code2 !== "" ? values.code2 : values.code && values.code !== "" ? values.code : "",
    }

    handleRequest("deposit_address", data)
      .then((data) => {
        if (data.success) {
          setActiveStep(3)
          model.setValue("wallet", data?.data?.wallet ?? "")
          handleNavigate("/order-card/confirmation")
        } else {
          Notice.error(data.message ?? "Something wrong!")
        }
      })
      .catch((e) => {
        Notice.error(e.message ?? "Something wrong!")
      })
      .finally(() => setIsSelecting(false))
  }, [model, handleNavigate, handleRequest])

  const onVerify = useCallback(async () => {
    setIsSelecting(true)
    const values = model.getValues()
    const isValid = await model.trigger("transactionHash")
    if (isValid) {
      const data = {
        token: confirmResult.token,
        tx: values?.transactionHash,
      }

      handleRequest("update_tx", data)
        .then((data) => {
          if (data.success && data.data) {
            return handleRequest("deposit_check", { token: confirmResult.token })
          }

          throw new Error(data.message ?? "Something wrong!")
        })
        .then((data) => {
          if (data.success) {
            setIsTransactionSuccess(true)
            setVerifyResponse(data.data)
          } else {
            Notice.error(data.message ?? "Something wrong!")
          }
        })
        .catch((e) => {
          Notice.error(e.message ?? "Something wrong!")
        })
        .finally(() => setIsSelecting(false))
    }
  }, [confirmResult.token, model, handleRequest])

  const onConfirm = useCallback(() => {
    setIsConfirming(true)

    const values = model.getValues()

    const data = {
      fiat_amount: values?.denomination,
      fiat_currency: `${values?.currency?.label}`,
      crypto_amount: `${values?.paymentMethod?.amount}`,
      crypto_currency: `${values?.paymentMethod?.crypto}`,
      email: values?.email,
      wallet: values.wallet,
      card_type: `${values?.cardType?.value}`,
      country: `${values?.country?.value}`,
      s_id: values.code2 && values.code2 !== "" ? values.code2 : values.code && values.code !== "" ? values.code : "",
    }

    handleRequest("order_card", data)
      .then((data) => {
        if (data.success) {
          setConfirmResult(data.data)
          handleNavigate("/order-card/transaction-id")
          setActiveStep(5)
        } else {
          Notice.error(data.message ?? "Something wrong!")
        }
      })
      .catch((e) => {
        Notice.error(e.message ?? "Something wrong!")
      })
      .finally(() => setIsConfirming(false))
  }, [model, handleNavigate, handleRequest])

  const onRefreshInformation = useCallback(async () => {
    try {
      await onRefresh()
      await onConfirm()
    } catch (e) {}
  }, [onConfirm, onRefresh])

  const onResetTransaction = useCallback(() => {
    model.setValue("transactionHash", "")
    setIsTransactionSuccess(false)
    setVerifyResponse(null)
  }, [model])

  const onFullReset = useCallback(() => {
    model.reset(DEFAULT_VALUES)
    setIsLoadingRates(false)
    setIsConfirming(false)
    setIsTransactionSuccess(false)
    setVerifyResponse(null)
    setIsSelecting(false)
    setBrandInfo(null)
    setRates([])
    setFees({})
    setFlatPrices({})
    setFlatPricesOptions([])
    setActiveStep(1)
    handleNavigate("/order-card/select")
  }, [model, handleNavigate])

  useEffect(() => {
    if (!isWidget) {
      window.addEventListener("popstate", function (e) {
        if (activeStep === 2) {
          setActiveStep(1)
          handleNavigate("/order-card/select")
        } else if (activeStep === 4) {
          setActiveStep(3)
          handleNavigate("/order-card/confirmation")
        } else {
          onFullReset()
        }
      })
    }
  }, [onFullReset, activeStep, handleNavigate, isWidget])

  return {
    searchValue,
    setSearchValue,
    page,
    setPage,
    onConfirm,
    confirmResult,
    onVerify,
    isConfirming,
    filteredCryptos,
    onFullReset,
    isSelecting,
    cardTypes,
    handleNavigate,
    brandInfo,
    isWidget,
    onSelect,
    onRefreshInformation,
    model,
    verifyResponse,
    isVisaCardType,
    isTransactionSuccess,
    activeStep,
    onResetTransaction,
    setActiveStep,
    isLoadingRates,
    rates,
    flatPricesOptions,
    currency,
    setPaymentMethod,
    onRefresh,
  }
}
