export const DropdownIcon = ({ up }: { up: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9px"
      height="6px"
      viewBox="0 0 9 6"
      version="1.1"
      className={`selector-custom__icon ${up ? "selector-custom__icon--up" : ""}`}
    >
      <g id="ui-kit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1256.000000, -1559.000000)" fill="#777E8A" id="inputs">
          <g transform="translate(120.000000, 1515.000000)">
            <g id="date-format-drop-list" transform="translate(900.000000, 0.000000)">
              <g id="get-support" transform="translate(0.000000, 27.000000)">
                <path
                  d="M243.424621,17 L244.485281,18.0606602 L240.242641,22.3033009 L236,18.0606602 L237.06066,17 L240.242,20.181 L243.424621,17 Z"
                  id="drop"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
