import { clsx, ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const makeOption = (option: string[]) => option.map((opt) => ({ label: opt, value: opt }))

export const getFormattedPhone = (input: string) => {
  const parsedData = parsePhoneNumberFromString(input?.trimEnd() ?? "")
  if (parsedData == null) return { formatted: input?.trimEnd() ?? "", data: null }
  const formatter = new AsYouType(parsedData.country)
  return { formatted: formatter.input(input), data: parsedData }
}

export const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  if (element != null) element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
}

export const ShuffleArray = (array: any[]) => array.sort(() => Math.random() - 0.5)
